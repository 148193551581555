import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'; // Importing Router for routing
import './App.css';
import 'animate.css';

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Router> {/* Wrapping the App in Router */}
      <div className="App">
        {/* Left Navigation Bar (Store Icon) */}
        <nav className="left-nav">
          <img src="/images/store-icon.png" alt="Store Icon" className="store-icon" />
        </nav>

        {/* Right Navigation Bar */}
        <nav className={`right-nav ${isMenuOpen ? 'open' : ''}`}>
          <ul className="nav-links">
            <li><a href="#services">Our Services</a></li>
            <li><a href="#contact">Contact Us</a></li>
            <li><a href="#about">About Us</a></li>
          </ul>
          <div className="hamburger" onClick={toggleMenu}>
            &#9776;
          </div>
        </nav>

        {/* Hero Section */}
        <section className="hero">
          <div className="hero-content">
            <div className="container">
              <h1 className="title animate__animated animate__fadeIn">Welcome to EVAREC CLEANING SERVICES</h1>
              <p className="description animate__animated animate__fadeIn animate__delay-1s">
                Your trusted partner for residential and commercial cleaning services.
              </p>
              <div className="cta-buttons">
                <a href="#services" className="cta-button animate__animated animate__fadeIn animate__delay-2s">Explore Our Services</a>
                <a href="#contact" className="cta-button contact-button animate__animated animate__fadeIn animate__delay-2s">Contact Us</a>
              </div>
            </div>
          </div>
          <div className="hero-image-container">
            <img src="/images/hero-image.jpg" alt="Cleaning Services" className="hero-image" />
          </div>
        </section>

        {/* Services Section */}
        <section id="services" className="services animate__animated animate__fadeIn">
          <div className="container">
            <h2>Our Services</h2>
            <div className="service-list">
              <div className="service">
                <img src="/images/residential-cleaning.jpg" alt="Residential Cleaning" className="service-image" />
                <h3>Residential Cleaning</h3>
                <p>We provide thorough and eco-friendly cleaning for your home, leaving it spotless and fresh.</p>
              </div>
              <div className="service">
                <img src="/images/commercial-cleaning.jpg" alt="Commercial Cleaning" className="service-image" />
                <h3>Commercial Cleaning</h3>
                <p>Our team ensures your office or business environment is clean and welcoming for your employees and clients.</p>
              </div>
              <div className="service">
                <img src="/images/after-party-cleaning.jpg" alt="After Party Cleaning" className="service-image" />
                <h3>After Party Cleaning</h3>
                <p>We handle the mess after your event, making sure your space is left clean and tidy.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section id="testimonials" className="testimonials animate__animated animate__fadeIn animate__delay-1s">
          <div className="container">
            <h2>What Our Clients Say</h2>
            <div className="testimonial-list">
              <div className="testimonial">
                <p>"EVAREC Cleaning Services always exceeds our expectations! Their attention to detail is exceptional."</p>
                <span>- Sarah, Homeowner</span>
              </div>
              <div className="testimonial">
                <p>"The team at EVAREC did an amazing job on our office space. It’s sparkling clean and fresh!"</p>
                <span>- Mike, Office Manager</span>
              </div>
            </div>
          </div>
        </section>

        {/* Call-to-Action Section */}
        <section id="cta" className="cta animate__animated animate__fadeIn animate__delay-2s">
          <div className="container">
            <h2>Ready for a cleaner, healthier space?</h2>
            <a href="#contact" className="cta-button">Contact EVAREC Cleaning Services</a>
          </div>
        </section>

        {/* New Section After CTA */}
        <section id="google-play" className="google-play-section">
          <div className="container">
            <h2>Get Our Cleaning Services on Google Play</h2>
            <p>Download our app now and book your cleaning service at the touch of a button.</p>
            <h3>EVAREC CLEANING SERVICES LIMITED</h3>
            <p>Your privacy matters to us. <Link to="/privacypolicy">Read our Privacy Policy</Link> to learn more about how we protect your data.</p>
          </div>
        </section>

        {/* Footer */}
        <footer className="footer animate__animated animate__fadeIn animate__delay-3s">
          <div className="container">
            <p>&copy; 2024 EVAREC CLEANING SERVICES LIMITED. All rights reserved.</p>
          </div>
        </footer>
      </div>

      {/* Route for Privacy Policy */}
      {/* <Routes>
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      </Routes> */}
    </Router>
  );
}

// // Privacy Policy Component
// function PrivacyPolicy() {
//   return (
//     <div className="privacy-policy">
//       <h1>Privacy Policy</h1>
//       <p>Here you can include the content of your privacy policy...</p>
//     </div>
//   );
// }

export default App;
